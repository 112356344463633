import React from 'react'

export const pageData = {
  title: 'Thank You',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Thank You',
      url: '/thank-you/existing-clients/',
    },
  ],
  uri: '/thank-you/existing-clients/',
  blogTitle: 'READ UP TO LEVEL UP YOUR BUSINESS',
}

export const bannerData = {
  heading: 'Thanks for contacting us!',
  paragraph: 'Our team will get in touch shortly, so keep your lines open.',
  button: {
    text: 'Back to Main page',
    link: '/',
  },
  alt: 'Featured Screenshots on devices',
}

export const blogData = {
  title: 'The Best Real Estate Agent Websites for 2022',
  button: {
    label: 'View our top 50 websites',
    url: '/the-best-real-estate-agent-websites-for-2022/',
  },
}

export const logosData = {
  title: 'OUR INDUSTRY PARTNERS',
  logos: [
    { alt: 'Real Trends', width: 270, height: 42 },
    { alt: 'HousingWire', width: 261, height: 54 },
    { alt: 'TomFerry', width: 239, height: 50 },
    { alt: 'Inman', width: 147, height: 43 },
    { alt: 'Inman Connect', width: 135, height: 78 },
    { alt: 'Luxury Portfolio International', width: 251, height: 61 },
  ],
}

export const accessData = {
  heading: 'Reinvent The Way You Sell Real Estate',
  paragraph: 'ACCESS Digital Listing and<br/> Open House Presentation Platform',
  button: { text: 'Learn more', link: 'https://www.access.com/' },
}
