import React from 'react'
import { getImage } from 'gatsby-plugin-image'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import {
  Banner,
  Logos,
  Access,
} from '@components/pages/existing-clients-thank-you'
import BlogArchiveBestRealEstate from '@components/pages/blog/archive/best-real-estate'
import ReviewsServiceSetsUsApart from '@components/global/Reviews/ServiceSetsUsApart'
import BlogPostsV3 from '@components/global/blog/BlogPostsV3'
import {
  pageData as page,
  bannerData,
  blogData,
  logosData,
  accessData,
} from '@src/data/ExistingClientsThankYou'
import useThankYouMetadata from '@hooks/existing-clients-thank-you-metadata'
import { useBlogArchiveMetadata } from '@hooks/blog-archive-metadata'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './ExistingClientsThankYou.module.scss'

const ExistingClientsThankYou = () => {
  const {
    bannerImg,
    logoReal,
    logoHousing,
    logoTom,
    logoInman,
    logoConnect,
    logoLuxury,
    accessLogo,
    accessImg,
    blogBGPhone,
  } = useThankYouMetadata()
  const logoImages = [
    logoReal,
    logoHousing,
    logoTom,
    logoInman,
    logoConnect,
    logoLuxury,
  ]
  const blogQuery = useBlogArchiveMetadata()
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767

  return (
    <LayoutInnerPage wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        breadcrumbs={page.breadcrumbs}
        heading={bannerData.heading}
        paragraph={bannerData.paragraph}
        buttonText={bannerData.button.text}
        buttonLink={bannerData.button.link}
        alt={bannerData.alt}
        img={extractImage(bannerImg)}
      />
      <BlogArchiveBestRealEstate
        wrapperClassName={cx.intro}
        title={blogData.title}
        buttonLabel={blogData.button.label}
        buttonUrl={blogData.button.url}
        featured={getImage(blogQuery.BestRealEstate)}
        logos={[
          getImage(blogQuery.RealTrends),
          getImage(blogQuery.Horizon),
          getImage(blogQuery.Vega),
          getImage(blogQuery.Hermes),
          getImage(blogQuery.Muse),
        ]}
      />
      <Logos
        title={logosData.title}
        logoImages={logoImages}
        logos={logosData.logos}
      />
      <ReviewsServiceSetsUsApart
        wrapperClassName={cx.reviews}
        title={page?.thankYou?.reviews?.title}
        subtitle={page?.thankYou?.reviews?.subtitle}
        backgroundUrl={page?.thankYou?.reviews?.review_background?.url}
        reviewItems={page?.thankYou?.reviews?.items}
      />
      <Access
        heading={accessData.heading}
        paragraph={accessData.paragraph}
        buttonText={accessData.button.text}
        buttonLink={accessData.button.link}
        logo={extractImage(accessLogo)}
        img={extractImage(accessImg)}
      />
      <BlogPostsV3
        wrapperClassName={cx.blog}
        title={page.blogTitle}
        background={extractImage(isPhone && blogBGPhone)}
      />
    </LayoutInnerPage>
  )
}

export default ExistingClientsThankYou
