import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import * as cx from './Access.module.scss'

const Access = ({
  wrapperClassName,
  heading,
  paragraph,
  buttonText,
  buttonLink,
  logo,
  logoAlt = 'Access logo',
  img,
  imgAlt = 'Access template screenshots',
  headingClassName = 'access-heading',
  bodyClassName = 'access-body',
}) => {
  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <div className={cx.container}>
        <div className={cx.content}>
          <div className={cx.logo}>
            <ElementImage src={logo} alt={logoAlt} />
          </div>
          <h4 className={headingClassName}>{heading}</h4>
          <p className={bodyClassName}>{parse(paragraph)}</p>
          <Link to={buttonLink}>
            <Button text={buttonText} />
          </Link>
        </div>
        <div className={cx.devices}>
          <ElementImage src={img} alt={imgAlt} />
        </div>
      </div>
    </section>
  )
}

Access.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  img: PropTypes.string,
  imgAlt: PropTypes.string,
  logo: PropTypes.string,
  logoAlt: PropTypes.string,
  headingClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
}

export default Access
