import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import * as cx from './Banner.module.scss'

const Banner = ({
  wrapperClassName,
  breadcrumbs,
  img,
  heading,
  paragraph,
  buttonText,
  buttonLink,
  alt,
  headingClassName = 'heading-2',
  paragraphClassName = 'subtitle-5',
}) => {
  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <Breadcrumbs
        wrapperClassName={`${cx.breadcrumbs} global-breadcrumbs`}
        data={breadcrumbs}
      />

      <div className={cx.container}>
        <div className={cx.content}>
          <h2 className={headingClassName}>{heading}</h2>
          <p className={paragraphClassName}>{paragraph}</p>
          <Link to={buttonLink}>
            <Button text={buttonText} />
          </Link>
        </div>

        <div className={cx.image}>
          <ElementImage src={img} alt={alt} lazy={false} />
        </div>
      </div>
    </section>
  )
}

Banner.propTypes = {
  wrapperClassName: PropTypes.string,
  breadcrumbs: PropTypes.array,
  img: PropTypes.string,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  alt: PropTypes.string,
  headingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
}

export default Banner
