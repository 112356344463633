import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Element
import ElementImageFile from '@components/global/element/ImageFile'

import * as cx from './BlogArchiveBestRealEstate.module.scss'

const BlogArchiveBestRealEstate = ({
  wrapperClassName,
  title,
  buttonLabel,
  buttonUrl,
  featured,
  logos,
}) => {
  return (
    <section
      id="blog-best-real-estate"
      className={clsx(cx.wrapper, wrapperClassName)}
    >
      <h2 className="heading-5">
        The Best Real Estate Agent Websites for 2022
      </h2>
      <Link to="#" className="primary-button">
        View our top 50 websites
      </Link>
      <div className={clsx(cx.featured)}>
        <ElementImageFile src={featured} lazy={true} />
      </div>

      <div className={clsx(cx.recognized)}>
        <p className="subtitle-7">as recognized by</p>

        <div className={clsx(cx.logos)}>
          <div className={clsx(cx.logoGroup)}>
            {logos[0] !== undefined && (
              <div>
                <ElementImageFile src={logos[0]} lazy={true} />
              </div>
            )}
            {logos[0] !== undefined && (
              <div>
                <ElementImageFile src={logos[1]} lazy={true} />
              </div>
            )}
            {logos[0] !== undefined && (
              <div>
                <ElementImageFile src={logos[2]} lazy={true} />
              </div>
            )}
          </div>
          <div className={clsx(cx.logoGroup)}>
            {logos[0] !== undefined && (
              <div>
                <ElementImageFile src={logos[3]} lazy={true} />
              </div>
            )}
            {logos[0] !== undefined && (
              <div>
                <ElementImageFile src={logos[4]} lazy={true} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

BlogArchiveBestRealEstate.propTypes = {
  wrapperClassName: PropTypes.string,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  featured: PropTypes.object,
  logos: PropTypes.array,
}

export default BlogArchiveBestRealEstate
