import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './Logos.module.scss'

const Logos = ({
  wrapperClassName,
  logos,
  logoImages,
  title,
  titleClassName = 'subtitle-7',
}) => {
  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <h3 className={titleClassName}>{title}</h3>

      <div className={cx.logos}>
        {logos.map((item, index) => {
          return (
            <div key={index} className={cx.logo}>
              <ElementImage
                src={extractImage(logoImages[index])}
                width={item.width}
                height={item.height}
                alt={item.alt}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

Logos.propTypes = {
  wrapperClassName: PropTypes.string,
  logos: PropTypes.array,
  logoImages: PropTypes.array,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
}

export default Logos
