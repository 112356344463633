import { useStaticQuery, graphql } from 'gatsby'

export const pageData = {
  title: 'Agent Image Blog',
  description:
    'Learn the tips and tools you need to succeed at real estate marketing in the digital age.',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    { text: 'Resources', url: '/blog/' },
    { text: 'Agent Image Blog', url: '/blog/' },
  ],
  uri: '/blog/',
  social: {
    heading: 'The latest updates from our social feeds.',
    subheading: 'Follow us on social',
  },
}

export const pageSearchData = {
  title: 'Blog Search Results',
  description:
    'Learn the tips and tools you need to succeed at real estate marketing in the digital age.',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    { text: 'Resources', url: '/blog/' },
    { text: 'Agent Image Blog', url: '/blog/' },
    { text: 'Blog Search Results', url: '/search/' },
  ],
  uri: '/search/',
  social: {
    heading: 'The latest updates from our social feeds.',
    subheading: 'Follow us on social',
  },
}

export const useBlogArchiveMetadata = () => {
  const query = useStaticQuery(graphql`
    query PageBlogArchiveMetadata {
      posts: allWpPost {
        edges {
          node {
            id
            title
            slug
            excerpt
            content
            primaryCategory {
              node {
                name
                slug
              }
            }
            featuredImage {
              node {
                url: sourceUrl
                srcSet
                sizes
                width
                height
                alt: altText
              }
            }
          }
        }
      }

      featured: allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Featured" } } } }
        }
        limit: 3
      ) {
        edges {
          node {
            id
            title
            slug
            excerpt
            content
            primaryCategory {
              node {
                name
                slug
              }
            }
            featuredImage {
              node {
                url: sourceUrl
                srcSet
                sizes
                width
                height
                alt: altText
              }
            }
          }
        }
      }

      blog: wpCategory(slug: { eq: "blog" }) {
        id
        name
        seo {
          canonical
          title
          metaDesc
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphAuthor
          opengraphDescription
          opengraphTitle
          opengraphDescription
          opengraphImage {
            sourceUrl
          }
          opengraphUrl
          opengraphSiteName
          opengraphPublishedTime
          opengraphModifiedTime
          twitterTitle
          twitterDescription
          twitterImage {
            sourceUrl
          }
          breadcrumbs {
            text
            url
          }
        }
      }

      BestRealEstate: file(
        relativePath: { eq: "blogging/best-real-estate-2022/featured.png" }
      ) {
        id
        ...BlogArchiveMetaDataImageQuality
      }
      Hermes: file(
        relativePath: { eq: "blogging/best-real-estate-2022/hermes.png" }
      ) {
        id
        ...BlogArchiveMetaDataImageQuality
      }
      Horizon: file(
        relativePath: { eq: "blogging/best-real-estate-2022/horizon.png" }
      ) {
        id
        ...BlogArchiveMetaDataImageQuality
      }
      Muse: file(
        relativePath: { eq: "blogging/best-real-estate-2022/muse.png" }
      ) {
        id
        ...BlogArchiveMetaDataImageQuality
      }
      RealTrends: file(
        relativePath: { eq: "blogging/best-real-estate-2022/real-trends.png" }
      ) {
        id
        ...BlogArchiveMetaDataImageQuality
      }
      Vega: file(
        relativePath: { eq: "blogging/best-real-estate-2022/vega.png" }
      ) {
        id
        ...BlogArchiveMetaDataImageQuality
      }
    }

    fragment BlogArchiveMetaDataImageQuality on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}
