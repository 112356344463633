import { useStaticQuery, graphql } from 'gatsby'

const useExistingClientsThankYouMetadata = () => {
  const query = useStaticQuery(graphql`
    query ExistingClientsThankYouMetadata {
      bannerImg: file(
        relativePath: { eq: "existing-clients-thank-you/others/banner-img.png" }
      ) {
        id
        ...ExistingClientsThankYouImageOptimize
      }

      logoReal: file(
        relativePath: { eq: "existing-clients-thank-you/logos/real.png" }
      ) {
        id
        ...ExistingClientsThankYouImageOptimize
      }
      logoHousing: file(
        relativePath: { eq: "existing-clients-thank-you/logos/housing.png" }
      ) {
        id
        ...ExistingClientsThankYouImageOptimize
      }
      logoTom: file(
        relativePath: { eq: "existing-clients-thank-you/logos/tom.png" }
      ) {
        id
        ...ExistingClientsThankYouImageOptimize
      }
      logoInman: file(
        relativePath: { eq: "existing-clients-thank-you/logos/inman.png" }
      ) {
        id
        ...ExistingClientsThankYouImageOptimize
      }
      logoConnect: file(
        relativePath: { eq: "existing-clients-thank-you/logos/connect.png" }
      ) {
        id
        ...ExistingClientsThankYouImageOptimize
      }
      logoLuxury: file(
        relativePath: { eq: "existing-clients-thank-you/logos/luxury.png" }
      ) {
        id
        ...ExistingClientsThankYouImageOptimize
      }

      accessLogo: file(
        relativePath: { eq: "existing-clients-thank-you/access/logo.png" }
      ) {
        id
        ...ExistingClientsThankYouImageOptimize
      }
      accessImg: file(
        relativePath: { eq: "existing-clients-thank-you/access/img.png" }
      ) {
        id
        ...ExistingClientsThankYouImageOptimize
      }
      blogBGPhone: file(
        relativePath: {
          eq: "existing-clients-thank-you/others/blog-bg-phone.jpg"
        }
      ) {
        id
        ...ExistingClientsThankYouImageOptimize
      }
    }

    fragment ExistingClientsThankYouImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useExistingClientsThankYouMetadata
